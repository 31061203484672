#connect {
  width: 100%;

  img {
    width: 40px
  }

  #logos {
    display: flex;
    justify-content: space-evenly;
    align-self: center;
    width: 200px;
    margin-bottom: 16px;
  }

  #linkedin {
    border-radius: 5px;
  }

  #cv-icon {
    border-radius: 5px;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 309px;
    width: 100%;

    input, textarea {
      width: 70%;
      margin-bottom: 16px;
    }

    textarea {
      height: 70%;
    }

    button {
      width: 150px;
      background-color: white;
      color: #33658A;
      border: solid 1px #33658A;
      transition: .2s ease-in-out;
    }

    button:hover {
      background-color: #33658A;
      color: white;
      border: solid 1px white;
    }
  }
}


@import '../App.scss';
@import '/src/mobileStyles/home.scss';

@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

header {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: fixed !important;
  text-align: center;
  z-index: -1;

  button {
    border: none;
    background-color: transparent;
  }
  img {
    width: 100px;
    height: 100px;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }
  #text{
    color: white;
    margin: auto;
    padding-top: 100px;
    @include flexCenter();
    flex-direction: column;

    h1 {
    margin: auto;
    font-size: 72px;
    }

    p {
      align-self: flex-end;
      margin: 0;
    }
  }
}

#body {
  background-color: white;
  width: 100vw;
}

.loading {
  background-size: auto;
  h1 {
    visibility: hidden;
  }
  p{
    visibility: hidden;
  }
  img {
    visibility: hidden;
  }
}

nav {
  width: 100%;
  bottom: 0px;
  margin-top: 100vh;
  @include flexCenter();
  top: 0px;
  position: sticky;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.468);
  z-index: 20;
  button {
    margin: auto;
    text-align: center;
    width: 25%;
    font-size: larger;
    height: 30px;

    border: none;
    color: #33658A;
    border: none;

  }
  p {
    transition: .2s ease-in-out;
  }
  p:hover {
    opacity: .5;
  }
}

.content {
  max-width: 1440px;
  padding: 0 130px 40px;
  margin: auto;
  h1 {
    margin: auto
  }
}


#aboutMe {
  @include flexCenter();
  flex-direction: column;
  h1 {
    margin-bottom: 40px;
  }
}

#projects {
  @include flexCenter();
  flex-direction: column;

  #projects-component {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 15px;
  }
}

#tech {
  @include flexCenter();
  flex-direction: column;
}

#connect {
  @include flexCenter();
  flex-direction: column;
  
  #source-code {
    align-self: flex-end;
    margin-right: 20px;
  }
}
  
.bounce-2 {
    animation-name: bounce-2;
    animation-timing-function: ease;
}
  @keyframes bounce-2 {
    0%   { transform: translateY(0); opacity: 0; }
    50%  { transform: translateY(-50px); opacity: 1; }
    100% { transform: translateY(0); opacity: 0; }
}

@include mobile() {
  .content {
    max-width: 100%;
    padding: 0 25px 40px;

    .horizontalLine {
      width: 100%;
    }

    #projects-component {
      @include flexCenter();
      max-width: 100%;

      .project-gif-landscape {
        max-width: 100%;

        img, video {
          width: 100%;
        }
      }
      
      .otherProjects {
        .thumbnail-container {
          flex-direction: column;
          align-items: center;
        }
      }
    }

    #techStack {
      align-items: center;
      justify-content: center;
    }

    #me {
      flex-direction: column;
      align-items: center;
    }

    .carousel-wrapper {
      .about-me-item {
        img {
          max-width: 100%;
          aspect-ratio: 16/9;
        }

        .text-contatiner {
          max-width: 100%
        }
      }
    }
  }

}

@include tablet {
  .content {
    padding: 0 50px 40px;
  }
}
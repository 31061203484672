@import '/src/mobileStyles/home.scss';

.project-gif-landscape {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: auto;
  margin-bottom: 30px;
  max-width: calc(50% - 15px);

  h3 {
    align-self: flex-start;
  }

  img, video {
    max-width: 500px;
    height: 300px;
    object-fit: contain;
    border-radius: 15px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.468);
    margin-bottom: 20px;
  }

  p {
    max-width: 600px;
    max-height: 250px;
    overflow: scroll;
  }

  
  a {
    transition: .2s ease-in-out;
    align-self: flex-start;
  }
}

.otherProjects {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 40px;

  h2 {
    align-self: flex-start;
    margin-bottom: 8px;
  }

  .thumbnail-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
    .thumbnail {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      img {
        height: 220px;
      }
      
      a {
        transition: .2s ease-in-out;
      }
    }
  }
}


.horizontalLine {
  color: black;
  background-color: black;
  height: 1px;
  border-color: black;
  width: 70%;
}

@mixin imageLeft {
  video, img {
    margin-right: auto;
  }
  p {
    margin-left: auto;
  }
}
@mixin imageRight {
  flex-direction: row-reverse;

  h3 {
    text-align: right;
  }
  video, img {
    margin-left: auto;
  }
  p {
    margin-right: auto;
  }
}
@include tablet {
  .project-gif-landscape {
    flex-direction: row;
    flex-wrap: wrap;
    max-width: unset;
    position: relative;
    
    h3 {
      width: 100%;
    }
    
    video, img, p {
      width: 48%;
    }
    
    p {
      max-height: 190px;
      overflow-y: scroll;
    }
    
    div, a {
      position: absolute;
      white-space: nowrap;
    }
    // caption
    div {
      bottom: -5px;
      left: 0;
    }
    
    // a outside of caption
    & > a {
      bottom: -5px;
      right: 0;
    }

    &:nth-child(odd) {
      @include imageLeft
    } 
    &:nth-child(even) {
      @include imageRight
    } 
  }
}
@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');

body {
  box-sizing: border-box;
  --color1: #2f4858ff;
  --color2: #33658aff;
  --color3: #86bbd8ff;
  --color4: #f6ae2db3;
  --color5: #f26419B3;
  width: 100vw;
  font-family: 'Quicksand', sans-serif;
}

@import '/src/mobileStyles/home.scss';

#techStack {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 30px;
  align-items: flex-start;
  width: 100%;
  
  section {
    margin: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      margin: 10px;
      width: 100px;
    }
  }
}

@include tablet {
  #techStack {
    justify-content: space-around;
  }
}
body {
  --color1: #2f4858ff;
  --color2: #33658aff;
  --color3: #86bbd8ff;
  --color4: #f6ae2db3;
  --color5: #f26419B3;
}
#secret {
  text-decoration: none;
  color: black;
}
#home {
  margin: auto;
  background-color: var(--color3);
  text-align: center;
  height: 1000px;
  font-family: monospace;
  a {
    text-decoration: none;
    color: var(--color1)
  }
  h1 {
    font-size: 42px;
    margin: auto;
    padding: 20px;
    max-width: 700px;
    width: auto;
    z-index: 1;
    position: relative;
    text-align: left;
    word-wrap: break-word;
  }
  h3 {
    max-width: 500px;
    width: auto;
    margin: auto;
    z-index: 1;
    position: relative;
    background-color: var(--color4);
    padding: 8px;
    border-radius: 10px;
  }
  p {
    position: relative;
  }
}

#orange-color-block {
  width: 300px;
  height: 300px;
  position: absolute;
  right: 10%;
  top: 3%;
  background-color: var(--color5);
  z-index: 0;
  border-radius: 30px 30px 30px 0 ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  img {
    width: 90%;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 420px) {
#home {
  background-color: var(--color3);
  text-align: center;
  height: 1000px;
  font-family: monospace;
  width: 100%;
  a {
    text-decoration: none;
    color: var(--color1)
  }
  h1 {
    font-size: 42px;
    margin: auto;
    padding: 20px;
    width: auto;
    z-index: 1;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
  }
  h3 {
    width: auto;
    margin: auto;
    background-color: var(--color4);
    padding: 8px;
    border-radius: 10px;
  }
}
  #orange-color-block {
    width: 300px;
    height: 300px;
    background-color: var(--color5);
    border-radius: 30px 30px 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color1);
    position: relative;
    margin: auto;
    margin-right: 0;
    img {
      width: 90%;
      border-radius: 10px;
    }
  }
}
#About {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.carousel-wrapper {
  margin-bottom: 40px;
  height: 500px;
  max-width: 100%;

  .about-me-item {
    max-width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    img {
      margin: 0 auto;
      height: 380px;
      object-fit: contain;
      max-width: 100%;
    }
    
    .text-container {
      background-color: #2f4858B3;
      padding: 8px
    }

    &.ltr, &.rtl {
      flex-direction: row;
      align-items: center;

      img {
        margin: auto 0;
      }

      .text-container {
        height: auto;
      }
    }
    
    &.rtl {
      flex-direction: row-reverse;
    }

    &.stacked {
      .text-container {
        height: auto;
        max-width: calc(380px * (16/9));
        margin: auto;
      }
    }

    &.overlay {
      margin: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      .text-container {
        width: calc(400px * (16/9));
        background-color: rgba(255,255,255, 0.8);
        color: #2f4858
      }
    }
  }
}

.pictureLeft {
  display: flex;
  justify-content: space-between;
  width: 800px;

  figure {
    margin: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: flex-end;
    img {
      width: 400px;
    }
  }

  div {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 300px;
    
    h3 {
      margin-top: 0;
      align-self: start;
    } 

  }
}

.pictureRight {
  display: flex;
  justify-content: space-between;
  width: 800px;
  margin: 60px 0 60px 0;
  figure {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
    img {
      width: 400px
    }
  }

  div {
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 300px;
    
    h3 {
      margin-top: 0;
      align-self: flex-start;
    }
  }
}

#me {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 60px 0 60px 0;
  gap: 60px;

  @media only screen and (max-width: 420px) {
    p {
      width: 250px;
      text-align: left;
    }
  }

  img {
    width: 250px;
    height: auto;
    border-radius: 50%;
    border: solid white 2px;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.468);
  }
}